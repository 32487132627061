<template>
  <div id="index">
  <dv-full-screen-container class="bg" v-if="loading">
     <dv-loading v-if="loading">Loading...</dv-loading>
  </dv-full-screen-container>
    <div class="bg" style="overflow: hidden;" v-else>
      <div class="logout" >
      <i class="el-icon-s-custom"></i>{{dashUser.realName}}
      <i class="el-icon-caret-right"></i>
      <span @click="logout()" >登出</span>
      </div>
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="position: relative;">
          <dv-decoration-10
            :color="['#568aea', 'rgba(86, 138, 234, .3)']"
            style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)"
          />
          <div class="d-flex jc-center ai-end">
            <div class="title">
              <div class="d-flex jc-center ai-center gas-fhj">
                <img :src="require('../assets/gas.png')" />
                <img :src="require('../assets/fhj.png')" />
                <img :src="require('../assets/yyh.png')" v-if="showYyh" />
              </div>
              <div class="title-text">{{ title }}</div>
            </div>
          </div>
          <dv-decoration-10
            :color="['#568aea', 'rgba(86, 138, 234, .3)']"
            style="width: 33.3%; height: 0.0625rem"
          />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-center ai-end px-2" style="margin-top:-0.05rem">
          <dv-decoration-8
            :color="['#568aea', '#568aea']"
            style="width: 3.2rem; height: 0.625rem"
          />
          <span
            style="
              font-size: 0.3rem;
              line-height: 1.2;
              color: #01a2e8;
              margin: 0 2rem;
            "
            @click="$router.go(0)"
            >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
          >
          <dv-decoration-8
            :reverse="true"
            :color="['#568aea', '#568aea']"
            style="width: 3.2rem; height: 0.625rem"
          />
        </div>

        <div class="body-box flex-1" >
          <div :class="areaType !== 4 ? 'left-box' : 'left-box3'"  >
            <div style="height:28vh">
            <dv-border-box-12 v-if="dashUser&&dashUser.type"
            style="height:28vh">
                <devicesStaticByRegion></devicesStaticByRegion>
              </dv-border-box-12>
              <dv-border-box-12 v-else>
                <statistics :data="data.deviceMonthlyStatis"  />
              </dv-border-box-12>
            </div>
            <div style="height:28vh;">
              <dv-border-box-12 style="height:28vh;">
                <alarmRankList
                  :list="data.lastWeekTowerEventRankList"
                  :isAdmin="isAdmin"
                />
              </dv-border-box-12>
            </div>
            <div v-if="areaType !== 4" style="height:30vh;">
              <dv-border-box-12 style="height:30vh;">
                <abnormalTrend :data="data.lastWeekAbnormalDevList" v-if="1>1"/>
                <deviceList :obj="data.lastWeekAbnormalDevList"></deviceList>
              </dv-border-box-12>
            </div>
          </div>

          <div class="center-box" style="height:86vh;">
            <div class="w-100" style="height:86vh;">
              <dv-border-box-12 >
                <chinaMap
                  v-if="isAdmin"
                 
                />
                <div v-else class="w-100 h-100 py-4 px-4">
                  <div
                    class="w-100 h-100"
                    style="border-radius: 0.2rem; overflow: hidden"
                  >
                    <baidu-map
                      v-if="1>1"
                      class="w-100 h-100"
                      ak="VVzhhvMiEMU6HbYstY5LieXBeiiZcY0O"
                      :center="center"
                      :zoom="zoom"
                      :dragging="true"
                      :scroll-wheel-zoom="true"
                      @moveend="syncCenterAndZoom"
                      @zoomend="syncCenterAndZoom"
                    >
                      <bm-marker
                        :position="center"
                        :dragging="false"
                        animation="BMAP_ANIMATION_BOUNCE"
                      >
                      </bm-marker>
                      <bm-control
                        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                        class="w-100 d-flex jc-evenly mb-4"
                      >
                        <div class="bmap-control-btn">全部：{{ total }}</div>
                        <div class="bmap-control-btn">火警：{{ fire }}</div>
                        <div class="bmap-control-btn">异常：{{ abnormal }}</div>
                        <div
                          v-if="areaType !== 4"
                          class="bmap-control-btn d-flex ai-center"
                          style="position: relative"
                          @click="showAreaList = !showAreaList"
                        >
                          <span class="mr-1">{{
                            area ? area : "全部区域"
                          }}</span>
                          <icon
                            name="chevron-down"
                            :style="{
                              transform: showAreaList
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                            }"
                          ></icon>
                          <div
                            v-if="showAreaList"
                            class="bmap-control-list d-flex flex-column"
                          >
                            <div
                              class="bmap-control-list-btn"
                              v-for="(item, index) in areaList"
                              :key="index"
                              @click="chooseArea(index)"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </div>
                      </bm-control>
                      <custom-overlay
                        v-for="(item, index) in list"
                        :key="index"
                        :info="item"
                        @escapeRoutes="escapeRoutes"
                      ></custom-overlay>
                    </baidu-map>
                  </div>
                </div>
              </dv-border-box-12>
            </div>
          </div>

          <div class="right-box" >
              <div style="height:30vh;" @click="showRealAlarm=true">
              <dv-border-box-12  key="2" style="height:30vh;">
                <alarmList
                  :obj="data.realTimeAlert"
                  :isAdmin="isAdmin"
                  @alarmDetail="alarmDetail"
                  @playMonitor="playMonitor"
                />
              </dv-border-box-12>
              </div>

             <div style="height:56vh;">
             <dv-border-box-12 key="3" style="height:56vh;">
                <access
                  :data="isAdmin ? townStatisList : data.townStatisList"
                  :isAdmin="isAdmin"
                />
              </dv-border-box-12>
              </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="逃生路线详情" :visible.sync="escape">
      <img class="w-100" :src="escapeImg" />
    </el-dialog>
    <el-dialog title="楼宇基本信息" :visible.sync="showAlarmDetail">
      <alarmDetail @deviceList="deviceList" />
    </el-dialog>
    <el-dialog title="楼宇设备列表" :visible.sync="showDeviceList">
      <deviceList :info="fowerInfo" />
    </el-dialog>


    <el-dialog title="报警信息" :visible.sync="showRealAlarm" width="75%" 
    :show-close="false" :close-on-esc="false" :close-on-click-modal="false"
    >
      <div style="background:#000;" v-if="data.realTimeAlert&&data.realTimeAlert.deviceList"> 
        <el-button type="text"  style="position:absolute;right:5px;top:5px;font-size:30px;" @click="closeRealAlarm">暂关弹窗(10分钟)</el-button>
        <el-table :data="data.realTimeAlert.deviceList" style="width: 100%" height="700">
          <el-table-column prop="deviceId" align="center" label="设备编号" > </el-table-column>
          <el-table-column prop="productName" label="名称"> </el-table-column>
          <el-table-column prop="deviceStatusName" label="状态" width="100"></el-table-column>
          <el-table-column prop="eventTypeName" label="报警类型" width="100"></el-table-column>
          <el-table-column prop="alarmTime" label="报警时间"></el-table-column>
          <el-table-column prop="detailAddress" label="地址" >
           <template slot-scope="scope">
              <div v-if="scope.row">
                <p>{{scope.row.detailAddress}}</p>
                <p class="d-flex ">
                <span>门牌：{{scope.row.houseNumber}};楼层：{{scope.row.floorNumber}};房间：{{scope.row.roomNum}};方位：{{scope.row.installPositionName}}</span>
                </p>
              </div>
          </template>
          </el-table-column>
          <el-table-column prop="handle" label="报警数据" >
           <template slot-scope="scope">
              <div v-if="scope.row.compositeSmokeDetector" @click="dataChangeTrend(scope.row)">
              <span>电池电量：{{scope.row.compositeSmokeDetector.batteryLevel}}%，</span>
              <span>空气湿度：{{scope.row.compositeSmokeDetector.humidity}}%RH，</span>
              <span>烟雾浓度：{{scope.row.compositeSmokeDetector.smokeConcentration}}dB/m，</span>
              <span>信号强度：{{scope.row.compositeSmokeDetector.signalStrength}}，</span>
              <span>环境温度：{{scope.row.compositeSmokeDetector.outsideTemperature}}°C，</span>
              <span>迷宫温度{{scope.row.compositeSmokeDetector.insideTemperature}}°C</span>
              </div>
          </template>
          </el-table-column>
          <el-table-column prop="handle" label="操作" width="200" align="center">
           <template slot-scope="scope">
              <div>
              <el-button type="warning" size="small" @click="controlEvent(scope.row,'消音')" v-if="!scope.row.isCompositeSmoke">消音</el-button>
              <el-button type="danger"  size="small" @click="controlEvent(scope.row,'消警')" v-else>消警</el-button>
              </div>
          </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      title="数据变化趋势"
      :visible.sync="showDataTrend"
      width="80%"
      :show-close="false"
      >
      <div style="height:700px;display:flex;padding:20px;
      justify-content:space-around;align-items:flex-start;" > 
      <humidityChart :cdata="compositeSmokeDetectorStatusAroundTime" eventType="humidity" title="湿度变化" />
    
      <footer>
        <el-button type="primary" @click="showDataTrend=false">关闭</el-button>
      </footer>

      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import bus from "@/utils/bus";
import { formatTime } from "../utils/index.js";
import alarmList from "./alarmList";
import statistics from "./statistics";
import alarmRankList from "./alarmRankList";
import abnormalTrend from "./abnormalTrend";
import chinaMap from "./chinaMap";
import access from "./access";
import CustomOverlay from "@/components/customOverlay";
import AlarmDetail from "@/components/alarmDetail";
import DeviceList from "./deviceList";
import devicesStaticByRegion  from './devicesStaticByRegion';

import humidityChart from "@/components/echart/humidityChart";

export default {
  data() {
    return {
      isAdmin: false,
      title: "国安达-防护家智能应急监测平台",
      loading: true,
      dateTimer: null,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      timer: null,
      data: null,
      townStatisList: [],
      chinaMapData: [],
      center: "",
      zoom: 15,
      areaType: null,
      areaList: [],
      area: "",
      showAreaList: false,
      list: [],
      total: 0,
      fire: 0,
      abnormal: 0,
      escape: false,
      escapeImg: "",
      showAlarmDetail: false,
      showDeviceList: false,
      fowerInfo: null,
      monitorList: [],
      dashUser:{},
      dashUrl:"",
      showYyh:false,
      showRealAlarm:false,
      sleep10Minute:false,
      showDataTrend:false,
      compositeSmokeDetectorStatusAroundTime:{},
      lastNewAlarm:{}
    };
  },
  components: {
    alarmList,
    statistics,
    alarmRankList,
    abnormalTrend,
    chinaMap,
    access,
    CustomOverlay,
    AlarmDetail,
    DeviceList,
    devicesStaticByRegion,
    humidityChart
  },
  created() {
    // 5567bb2a30fe42b4bdc82cf82ec8d175
    if (this.$route.query.token) {
      document.title = "社区可视化大屏";
      localStorage.setItem("baseUrl", "https://service.fhj.thesetech.com");
      localStorage.setItem("token", this.$route.query.token);
    } else {
      document.title = "国安达-防护家智能应急监测平台";
    }
    this.isAdmin = localStorage.getItem("baseUrl").indexOf("admin") !== -1;
    if(localStorage.getItem("baseUrl").indexOf("dash") !== -1){
      this.isAdmin = true;
      this.dashUser = localStorage.getItem("dashUser")
      
    }
    this.dashUrl = localStorage.getItem("dashUrl");

    this.getData();
    // this.getMonitorList();

    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.getData();
    }, 120000);
  },
  mounted() {
    this.timeFn();
  },
  destroyed() {
    this.dateTimer && clearInterval(this.dateTimer);
    this.timer && clearInterval(this.timer);
  },
  methods: {
    logout(){
      this.$router.push({path: '/login'});
    },
    playMonitor(e) {
      // if (!this.$store.state.dashUser) {
        bus.$emit("monitorSetUrl", e);
      // }
    },
    escapeRoutes(e) {
      this.escape = true;
      this.escapeImg = e;
    },
    alarmDetail() {
      this.showAlarmDetail = true;
    },
    deviceList(e) {
      this.showDeviceList = true;
      this.fowerInfo = e;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.center = {
        lng: lng,
        lat: lat,
      };
      this.zoom = e.target.getZoom();
      this.getDeviceList(lng, lat);
    },
    timeFn() {
      this.dateTimer && clearInterval(this.dateTimer);
      this.dateTimer = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH : mm : ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];

        if (this.dateDay == `08: 55: 00` || this.dateDay == `13: 55: 00`) {
          this.$router.go(0);
        }
        this.realTimeAlarm();
      }, 1000);
    },
    getProvinceData() {
      if (this.isAdmin) {
        this.$get(this.$apis.adminDevstatisProvince, {
          limit: 1000,
          page: 1,
        }).then((res) => {
          if (res && res.code === 0) {
            var arr = [];
            var list = [];
            res.data.list.forEach((element) => {
              if (element.province != null) {
                arr.push({
                  town: element.province,
                  towerCount: element.towerCount,
                  deviceCount: element.totalDevicesCount,
                });
                list.push(element);
              }
            });
            this.townStatisList = arr;
            this.chinaMapData = list;
          }
        });
      }
    },
    getData() {
      let baseUrl = localStorage.getItem("baseUrl")
      if(baseUrl.indexOf('dash')!=-1){
        // this.dashUser = this.$store.state.dashUser
        this.getDashboardData();
          setTimeout(() => {
            this.loading = false;
          },1500);
        return;
      }
      this.$get(
        this.isAdmin ? this.$apis.adminUserIndex : this.$apis.wechatUserIndex
      ).then((res) => {
        if (res && res.code === 0) {
          if (this.isAdmin) {
            this.title = "国安达-防护家智能应急监测平台";
            res.data.realTimeAlert = {
              total: res.data.alarmDeviceCount,
              untreatedCount: res.data.unprocessedDevicesCount,
              alreadyProcessedCount: res.data.processedDevicesCount,
              deviceList: res.data.alarmDeviceList,
              // deviceList: this.$store.state.dashUser
              //   ? res.data.alarmDeviceList
              //   : [],
            };
            var arr = [];
            res.data.regioRank.forEach((element) => {
              arr.push({
                tower: element.tower,
                totalEventCount: element.eventCount,
              });
            });
            res.data.lastWeekTowerEventRankList = arr;
            res.data.deviceCount = res.data.totalDevicesCount;
            res.data.deviceMonthlyStatis = res.data.eventStaticRatios;
            var abnormalTrend = [];
            res.data.weekTrend.forEach((element) => {
              abnormalTrend.push({
                time: element.statisTime.replace("-", "/"),
                totalFireAlarmDeviceCount: element.totalFireAlarmDeviceCount,
                totalMalfunctionDeviceCount:
                  element.totalMalfunctionDeviceCount,
                totalOfflineDeviceCount: element.totalOfflineDeviceCount,
                growthRate: Math.floor(
                  (element.totalEventCount / element.totalDevicesCount) * 100
                ),
              });
            });
            res.data.lastWeekAbnormalDevList = abnormalTrend;
          } else {
            if (res.data.roleType === 1) {
              this.$router.replace({
                path: "/building",
                query: {
                  token: localStorage.getItem("token"),
                },
              });
            }
            this.title = "消防监测平台";
            this.areaType = res.data.areaOperational.areaType;
            if (res.data.areaOperational.area) {
              this.title = `${res.data.areaOperational.area}消防监测平台`;
            }
            if (this.areaType === 4) {
              this.area = res.data.areaOperational.area;
            } else {
              this.areaList = res.data.areaOperational.areaList;
            }
            this.center = res.data.areaOperational.area;
            this.getDeviceList();
          }
          this.getProvinceData();
          this.data = res.data;

          setTimeout(() => {
            this.loading = false;
          }, 100);
        }
      });
    },
    getDeviceList(lng, lat) {
      var obj = {
        pageNum: 1,
        pageSize: 1000,
        status: 0,
        area: this.area,
      };
      if (lng) {
        obj.longitude = lng;
      }
      if (lat) {
        obj.latitude = lat;
      }
      this.$get(this.$apis.deviceList, obj).then((res) => {
        if (res && res.code === 0) {
          this.list = res.data.list;
          this.total = res.data.total;
          var fire = 0;
          var abnormal = 0;
          this.list.forEach((element) => {
            if (element.deviceStatus == 4) {
              fire += 1;
            }
            if (element.deviceStatus != 1) {
              abnormal += 1;
            }
          });
          this.fire = fire;
          this.abnormal = abnormal;
        }
      });
    },
    chooseArea(i) {
      this.area = this.areaList[i].name;
      this.center = this.data.areaOperational.area + this.areaList[i].name;
      this.getDeviceList();
    },
    getMonitorList() {
      if (this.dashUrl) {
        return;
      }
      this.$get(
        this.isAdmin ? this.$apis.adminMonitorList : this.$apis.monitorList,
        {
          type: 0,
          keyword: "",
        }
      ).then((res) => {
        if (res && res.code === 0) {
          this.monitorList = res.data;
        }
      });
    },

    getDashboardData() {
      if(!this.data){
        this.data = {};
      }
      let dashUser  = localStorage.getItem("dashUser")
          dashUser = JSON.parse(dashUser)
      // const dashUser = this.$store.state.dashUser;
      this.dashUser = dashUser;
      const regionType = dashUser.type;
      let dashUrl = localStorage.getItem("dashUrl");
      if(!dashUrl){
        return;
      }
      
      if(dashUser.provinceId>=500000){
        let title = "国安达-防护家-样样好   智能应急监测平台";
            document.title = title;
            this.title = title;
            this.showYyh = true;
      }
      let params = {
        regionType: dashUser.type,
      };
      switch (regionType) {
        case 1:
          params.provinceId = dashUser.provinceId;
          break;
        case 2:
          params.cityId = dashUser.cityId;
          break;
        case 3:
          params.countryId = dashUser.countryId;
          break;
      }

      /**
       * 面板统计
       */
      let dashboardUrl = "/api/dashboard/dashboard";
      this.$get(dashUrl + dashboardUrl, params, {}, true).then((res) => {
        let realTimeAlert = {
          total: res.data.alarmDeviceCount,
          untreatedCount: res.data.unprocessedDevicesCount,
          alreadyProcessedCount: res.data.processedDevicesCount,
          deviceList: [],
        };

        this.fireAlarmCount = res.data.totalFireAlarmDeviceCount;
        this.totalCount = res.data.totalDevicesCount; 
          
        
        /**
         * 获取设备告警列表
         */
        let alarmUrl = "/api/dev/devonlinedevice/alertsRealtimePage";
        this.$get(
          dashUrl + alarmUrl,
          {
            page: 1,
            limit: 100,
            ...params,
          },
          {},
          true
        ).then((res) => {
          realTimeAlert.deviceList = res.data.list;
          this.data.realTimeAlert = realTimeAlert;
          
          if(this.lastNewAlarm.id != res.data.list[0].id){
              this.showRealAlarm = true
          }
          this.lastNewAlarm = res.data.list[0]
        });

      });

      /**
       * 获取上个月的设备排名
       * 按楼宇设备安装数排名
       */

      this.$get(
        // dashUrl + "/api/dashboard/getLastMonthTowerEventRank",
        dashUrl + "/api/dev/devonlinedevice/countDevicesByTower",
        {...params,regionType:1},
        {},
        true
      ).then((res) => {
        var arr = [];
        res.data.forEach((element) => {
          arr.push({
            tower: element.tower,
            totalEventCount: element.totalCount,
          });
        });
        this.data.lastWeekTowerEventRankList = arr;
      });
      /**
       * 近一周设备异常
       * 设备列表
       */
      this.$get(
        // dashUrl + "/api/dashboard/deviceStatisticsWeeklyTrends",
        dashUrl + "/api/dev/devonlinedevice/page",
        { ...params,
          page:1,limit:10
        },
        {},
        true
      ).then((res) => {
        var arr = res.data;
        console.log("aaaa",res)
        this.data.lastWeekAbnormalDevList = arr;
      });

      // deviceMonthlyStatis  设备情况月统计

      /**
       * 监控
       */

      this.$get(
        this.dashUrl + "/api/dev/monitordevice/page",
        params,
        {},
        true
      ).then((res) => {
        if (res && res.code === 0) {
          this.monitorList = res.data.list;
        }
      });
    },
    realTimeAlarm(){
       /**
         * 获取设备告警列表
         */
      const dashUser = this.dashUser;
      const regionType = dashUser.type;
      let dashUrl = localStorage.getItem("dashUrl");
      let params = {
        regionType: dashUser.type,
      };
      switch (regionType) {
        case 1:
          params.provinceId = dashUser.provinceId;
          break;
        case 2:
          params.cityId = dashUser.cityId;
          break;
        case 3:
          params.countryId = dashUser.countryId;
          break;
      }
        let alarmUrl = "/api/dev/devonlinedevice/alertsRealtimePage";
        this.$get(
          dashUrl + alarmUrl,
          {
            page: 1,
            limit: 100,
            ...params,
          },
          {},
          true
        ).then((res) => {
          
          if(this.lastNewAlarm.id != res.data.list[0].id){
              this.showRealAlarm = true
          }
          this.lastNewAlarm = res.data.list[0]
          this.data.realTimeAlert.deviceList = res.data.list;

        });
    },
    controlEvent(item,type){
      let params = {
        deviceId:item.deviceId
      }
      let apiUrl = type=='消音' 
      ? "/api/dev/devonlinedevice/sendOffSmokeAlarmRequestToIotDevice" 
      :"/api/dev/devonlinedevice/sendOffSmokeAlarmRequestToIotDevice" ;
      this.$post(this.dashUrl + apiUrl,
        params,{},true).then((res) => {
        if (res && res.code === 0) {
            this.$message({
               message: `${type}成功`,
               type: 'success',
               center:true,
               offset:240                
            });   
            this.getDashboardData();       
        }
      });
    },
    closeRealAlarm(){
      this.showRealAlarm = false;
      this.sleep10Minute =  true; 
      this.getDashboardData();
      setTimeout(function() {
        this.sleep10Minute =  false;
      }, 10 * 60 * 1000); // 十分钟的毫秒数 
    },

    dataChangeTrend(item){
      
      let eventTimestamp = new Date(item.alarmTime).getTime()/1000
      let params = {
        deviceId:item.deviceId,
        eventTimestamp:eventTimestamp
      }
      this.$get(
        this.dashUrl + "/api/dev/devonlinedevice/getCompositeSmokeDetectorStatusAroundTime",
        params,{},true).then((res) => {
        if (res && res.code === 0) {
          this.showDataTrend = true
          let times = [];
          let humiditys = [];
          let smokeConcentrations = [];
          let insideTemperatures = [];
          let outsideTemperatures = [];
          res.data.humidityChanges.forEach((item,index)=>{
            times.push(formatTime(item.time*1000, "HH:mm:ss"));
            humiditys.push(item.value);
            smokeConcentrations.push(res.data.smokeConcentrationChanges[index].value);
            insideTemperatures.push(res.data.insideTemperatureChanges[index].value)
            outsideTemperatures.push(res.data.outsideTemperatureChanges[index].value)
          })
            let newData = {
            times: times.reverse(),
            humiditys: humiditys.reverse(),
            smokeConcentrations: smokeConcentrations.reverse(),
            insideTemperatures: insideTemperatures.reverse(),
            outsideTemperatures: outsideTemperatures.reverse()
          };
          console.log("newData",newData)
          this.compositeSmokeDetectorStatusAroundTime = newData;

        }
      });

    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
body::-webkit-scrollbar {
  display: none;
}
.logout{
  position:fixed;
  top:0px;
  right:0px;
  color:#11d0c9;
  font-weight:bold;
  z-index:999;
  padding:10px;
  padding-right:0;
  span{
    border-radius:2px;
  }
 
}



//主要是加上  ::v-deep
 
::v-deep .el-dialog {
    --el-bg-color: #000741 !important;

    border: 2px solid #1EFFFF; /* 透明的边框 */
    box-shadow: 0 0 10px 5px #1EFFFF; /* 发光效果的阴影 */
    transition: box-shadow 0.3s ease-in-out; /* 添加过渡效果 */
}


 
//弹框头部
::v-deep .el-dialog__header {
    --el-text-color-primary: #1EFFFF;
    --el-text-color-regular: #fff;
    padding: 0 !important;
    width: 100%;
    height: 64px;
    background: #000741 !important;
    color:#1EFFFF;
}

//弹框标题
::v-deep .el-dialog__title {
    margin-left: 24px;
    line-height: 64px;
}
//弹框内容部分
::v-deep .el-dialog__body {
    --el-bg-color: #000741 !important;
    --el-text-color-regular: #fff;
    background:#000741 !important;
    padding:0;
    color:#FFF !important;
}

/* 暗黑主题 */

::v-deep .el-dialog__title{
  color:#1EFFFF;
  font-size:26px;
}
::v-deep table{
  background-color:#000741 !important;
}
::v-deep th{
  background-color:#000741 !important;
  font-size:0.2rem;
}
::v-deep td{
  background-color:#000741 !important;
  font-weight:500;
}

/* 表头字体颜色 */
.el-table thead{
  color: #c8cfdd;
}

/* 表格字体颜色 */
.el-table{
  color: #c8cfdd;
  border-spacing: 0;
}

/* 头部 */
.el-table th.el-table__cell {
  background-color: #142143;
}



/* 侧边空白区域 */
.el-table__header-wrapper{
  background-color: #142143;
}
.el-table__body-wrapper {
  background-color: #142143;
}

.el-table__fixed-right-patch{
  background-color: #142143 !important;
}

/* 单数行 */
.el-table tr {
  background-color: #0c193f;
  /* background-color: #05133f; */
}
/* 偶数行 */
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: #142143;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #142143;
}

/* 表格悬浮 */
.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #29385e !important;
    /* background-color: #1f3157 !important; */
}

/* 表格底部线条bug */
.el-table::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
}


// 滚动条样式
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #063570;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  opacity: 0.5;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #0257aa;
}

</style>
